<template>
  <div>
    <PageHeader title="Versions" />

    <PageContent>
      <div
        v-for="app in applications"
        :key="app.slug"
        class="grid text-lg"
      >
        <p>{{ app.label }} - Version actuelle: {{ app.currentVersion }}</p>
        <div class="flex space-x-3">
          <input
            v-model="app.version"
            class="border-black border"
          />
          <button
            @click="setVersion(app.slug, app.version)"
            class="rounded bg-blue-400 text-white p-1.5"
          >Mettre à jour la version</button>
        </div>
      </div>
    </PageContent>
  </div>
</template>

<script>
import { http } from '@/services/api.service'
import PageHeader from '@/components/PageHeader'
import PageContent from '@/components/PageContent.vue'

export default {
  name: 'app-versions',
  components: {
    PageHeader,
    PageContent
  },
  data () {
    return {
      applications: [
        { label: 'Maviepro', slug: 'maviepro', version: '', currentVersion: '' },
        { label: 'Tract', slug: 'tract', version: '', currentVersion: '' },
        { label: 'Merlin', slug: 'merlin', version: '', currentVersion: '' }
      ]
    }
  },
  methods: {
    async getVersion (slug) {
      try {
        const response = await http.get(`/application/${slug}/version`)
        const app = this.applications.find(a => a.slug === slug)
        app.currentVersion = response.data.version
      } catch (e) {
        console.log('Error fetching version:', e)
      }
    },
    async setVersion (slug, version) {
      try {
        await http.post(`/application/${slug}/version`, { version })
        this.getVersion(slug)
      } catch (e) {
        console.log('Error setting version:', e)
      }
    }
  },
  mounted () {
    this.applications.forEach(app => {
      this.getVersion(app.slug)
    })
  }
}
</script>
